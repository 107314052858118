import React from 'react'

export default () => (
  <section id="about">
    <h2>About</h2>
    <p>
      I am an experienced Lead Software Engineer with a demonstrated history of building and leading teams to deliver high-quality, strategic solutions. I have strong communication skills and collaboration expertise with a track record of defining clear objectives and requirements to enable teams for success.
    </p>
    <p>
      I have a curious and innovative mindset. I pride myself on bringing creativity to problem solving and removing blockers from teams. I find great satisfaction in automating processes that are otherwise pain points for engineers by developing internal solutions and procedures.
    </p>
  </section>
);
