import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './scroll'

import Social from './social'
import avatar from '../assets/images/avatar.jpg'

const Links = [
  { content: 'Home', href: 'home' },
  { content: 'About', href: 'about' },
  { content: 'Employment', href: 'employment' },
  { content: 'Education', href: 'education' },
  { content: 'Skills', href: 'skills' },
  { content: 'Awards & Honors', href: 'awards-honors' },
  { content: 'Open Source', href: 'open-source' },
  { content: 'Resume', href: 'resume' },
]

const Header = () => (
  <header id="header">
    <div className="inner">
      <span className="image avatar">
        <img src={avatar} alt="" />
      </span>
      <Scrollspy
        items={Links.map(s => s.href)}
        className="nav"
        currentClassName="active"
      >
        {Links.map((tab, i) => {
          const { href, content } = tab
          return (
            <li className="nav-item" key={href}>
              <Scroll type="id" element={href}>
                <a className="nav-link" href={`#${href}`}>
                  {content}
                </a>
              </Scroll>
            </li>
          )
        })}
      </Scrollspy>
    </div>
    <Social />
  </header>
)

export default Header
