import React from 'react'

import Twitter from '../assets/static/twitter.png'
import LinkedIn from '../assets/static/linkedin.png'
import GitHub from '../assets/static/github.png'
import Email from '../assets/static/email.png'

class Social extends React.Component {
  render() {
    return (
      <div id="social">
        <div className="inner">
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/michaelwnelson"
                rel="noopener noreferrer"
                target="_blank"
                title="Twitter"
              >
                <img alt="Twitter" src={Twitter} />
              </a>
            </li>
            <li>
              <a
                href="https://linkedin.com/in/michaelwnelson7"
                rel="noopener noreferrer"
                target="_blank"
                title="LinkedIn"
              >
                <img alt="LinkedIn" src={LinkedIn} />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/michaelwnelson"
                rel="noopener noreferrer"
                target="_blank"
                title="GitHub"
              >
                <img alt="GitHub" src={GitHub} />
              </a>
            </li>
            <li>
              <a href="mailto:me@michaelwnelson.com" title="Email">
                <img alt="Email" src={Email} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Social
