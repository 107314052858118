import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Home from './home'
import About from './about'
import Employment from './employment'
import Education from './education'
import Skills from './skills'
import Awards from './awards'
import OpenSource from './open-source'
import Resume from './resume'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="main">
      <Home />
      <About />
      <Employment />
      <Education />
      <Skills />
      <Awards />
      <OpenSource />
      <Resume />
    </div>
  </Layout>
)

export default IndexPage
