import React from 'react'

import ResumeImage from '../assets/static/resume.png'
import Resume from '../assets/static/Michael-Nelson-Resume.pdf'

export default () => (
  <section id="resume">
    <h2>Resume</h2>
    <a href={Resume}>
      <img
        src={ResumeImage}
        alt="Michael Nelson\'s Resume"
        style={{ maxHeight: 300 }}
      />
    </a>
  </section>
);
